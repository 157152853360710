import React from 'react'

function Experience() {
  return (
    <div><div className="backround-col">
    <h2 id="about-head">MY EXPERIENCE.</h2>
    My expertise includes front-end development using HTML, CSS, and JavaScript, as well as back-end development with languages such as Python I am also experienced in using popular web development frameworks like react, jQuery<br />
        <br />
        If you are looking for a skilled and experienced web developer who is passionate about creating high-quality web applications, please don't hesitate to reach out. I would be delighted to discuss your project and see how I can help bring your vision to life.
 
  </div>
  </div>
  )
}

export default Experience



