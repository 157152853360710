import React from 'react'
import '../styles/Home.css';

function Home() {

  return (
    
    <div className="backround-col">
      <h2 id="about-head">I AM AUSTIN MCCLARREN.</h2>
      <p className="about-me">
        Hello, nice seeing you here. I am a passionate web developer with a background in front-end and back-end development.<br />
        <br/>
      </p>
      <div>
    </div>
    </div>
    
  );
  
}

export default Home