import React from 'react'


function Resume() {
  return (
    <div><div className="backround-col">
    <h2 id="about-head">MY RESUME.</h2>
    <a className='myresume' href="https://resume.io/r/UoiGSsSwp" target="_blank" rel="noreferrer">THE HOLY GRAIL(clickme)</a>
    <br />
        
  </div>


  
  </div>
  )
}

export default Resume

